import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';
import './HeroTrain.css';

import posterImg from '../../components/Assets/Images/trainposter.jpeg';

class HeroTrain extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div>
        <div className="herotraining">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="training-header-text wow fadeInDown">
                    CERTIFICATIONS
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
        <div className="train wow fadeInDown">
          <div className="container">
            <h3>AWS Certified Solutions Architect – Associate</h3>
            <p>
              Validate your technical skills and expertise with an
              industry-recognized credential and grow your career.
            </p>
          </div>
        </div>
        <div className="preparing wow fadeInDown">
          <div className="container">
            <h4>AWS CERTIFICATION EXAM READINESS TRAINING</h4>
            <h3>
              Preparing to Take the AWS Certified Cloud Practitioner
              Certification Exam?
            </h3>
            <p>
              Join our free virtual exam readiness webinar led by AWS-accredited
              instructors.
            </p>
          </div>
        </div>
        <div className="trainbody container">
          <Row>
            <Col sm={8}>
              <div>
                <h4 className="wow fadeInDown">
                  The AWS Certified Solutions Architect - Associate examination
                  is intended for individuals who perform a solutions architect
                  role and have one or more years of hands-on experience
                  designing available, cost-efficient, fault-tolerant, and
                  scalable distributed systems on AWS.
                </h4>
                <h3 className="wow fadeInDown">
                  Abilities Validated by the Certification
                </h3>
                <ul className="wow fadeInDown">
                  <li>
                    Effectively demonstrate knowledge of how to architect and
                    deploy secure and robust applications on AWS technologies
                  </li>
                  <li>
                    Define a solution using architectural design principles
                    based on customer requirements
                  </li>
                  <li>
                    Provide implementation guidance based on best practices to
                    the organization throughout the life cycle of the project
                  </li>
                </ul>
                <h3 className="wow fadeInDown">
                  Recommended Knowledge and Experience
                </h3>
                <ul className="wow fadeInDown">
                  <li>
                    Hands-on experience using compute, networking, storage, and
                    database AWS services
                  </li>
                  <li>
                    Hands-on experience with AWS deployment and management
                    services
                  </li>
                  <li>
                    Ability to identify and define technical requirements for an
                    AWS-based application
                  </li>
                  <li>
                    Ability to identify which AWS services meet a given
                    technical requirement
                  </li>
                  <li>
                    Knowledge of recommended best practices for building secure
                    and reliable applications on the AWS platform
                  </li>
                  <li>
                    An understanding of the basic architectural principles of
                    building on the AWS Cloud
                  </li>
                  <li>An understanding of the AWS global infrastructure</li>
                  <li>
                    An understanding of network technologies as they relate to
                    AWS
                  </li>
                  <li>
                    An understanding of security features and tools that AWS
                    provides and how they relate to traditional services
                  </li>
                </ul>
                <h3 className="wow fadeInDown">Prepare for Your Exam</h3>
                <p className="wow fadeInDown">
                  There is no better preparation than hands-on experience. There
                  are many relevant AWS Training courses and other resources to
                  assist you with acquiring additional knowledge and skills to
                  prepare for certification. Please review the exam guide for
                  information about the competencies assessed on the
                  certification exam.
                </p>
              </div>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default HeroTrain;
