import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';
import './HeroService.css';

export class HeroService extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div>
        <div className="heroservice">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="service-header-text wow fadeInDown">
                    SERVICE
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
        <div className="proud container">
          <Row>
            <Col sm>
              <div className="head-text">
                <h3 id="solve" className="animated bounce delay-5s">
                  WE SOLVE
                </h3>
                <h3 id="problem" className="animated bounce delay-5s">
                  YOUR <span style={{ color: '#3480eb' }}>"I.T"</span> PROBLEMS
                </h3>
              </div>
            </Col>
            <Col sm>
              <div className="staffing animated pulse">
                <p id="head">I.T CONSULTANCY & SOFTWARE DEVELOPMENT</p>
                <p id="middle"></p>
                <p id="body">
                  Sourcing talent from unfamiliar geographies is really hard to
                  do, especially where you don’t have a strong brand. Your time
                  should be spent focusing on your customer and product, not
                  recruiting tech talent across the globe. At Nescotouch
                  Technologies, our deep ties to local communities, our strong
                  brand presence, and our dedicated team has enabled us to
                  source over 20 I.T engineers (and counting).
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="servicestart" style={{ marginTop: 50 }}>
          <div className="startcontainer container">
            <Row>
              <Col sm className="wow lightSpeedIn">
                <h3>1.</h3>
                <p>
                  At Nescotouch Technologies, we offer IT application
                  development and maintenance services.
                </p>
              </Col>
              <Col sm className="wow lightSpeedIn">
                <h3>2.</h3>
                <p>
                  We help organizations build and support their IT applications,
                  reduce operating costs and position themselves to quickly take
                  advantage of advanced technologies that can help grow their
                  business. Our leading-edge delivery model, which can provide
                  flexibility and ease of engagement, also produces higher
                  levels of predictability and quality.
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm className="wow lightSpeedIn">
                <h3>3.</h3>
                <p>
                  Highly talented information technology professional that
                  handle every unique and niche requirements of our clients. We
                  are currently serving our clients in all American geographical
                  regions and provide application development and support
                  services across the following areas.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroService;
