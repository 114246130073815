import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";
import HeroService from "./HeroService/HeroService";

export default function Service() {
  return (
    <div>
      <NavBar />
      <HeroService />
      <Footer />
    </div>
  );
}
