import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Index from './Index/Index';
import Service from './Service/Service';
import Training from './Training/Training';
import About from './About/About';
import Contactus from './Contactus/Contactus';
import ReactGA from 'react-ga';

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/service" component={Service} />
          <Route path="/certification" component={Training} />
          <Route path="/about" component={About} />
          <Route path="/contactus" component={Contactus} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
