import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import WOW from 'wowjs';
import './Hero.css';

import trainingImg from '../../components/Assets/carousel/training.jpg';
import teamImg from '../../components/Assets/carousel/team.jpg';
import applicationImg from '../../components/Assets/carousel/applications.jpg';
import digitalImg from '../../components/Assets/carousel/digital.jpg';
import qualityresultImg from '../../components/Assets/carousel/qualityresult.jpg';

export class Hero extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="hero">
        <div className="images">
          <Carousel>
            <Carousel.Item>
              <img src={trainingImg} alt="Training" />
              <Carousel.Caption>
                <div className="heroText wow fadeInDown">
                  <h3>Welcome to Nescotouch Technologies.</h3>
                  <p>I.T Consultancy and Software Development.</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img src={applicationImg} alt="We Build Applications" />
              <Carousel.Caption>
                <div className="heroText wow fadeInDown">
                  <h3>We build Applications.</h3>
                  <p>
                    We are creatively driven consulting company, focused on
                    developing interactive projects.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img src={digitalImg} alt="We create Digital Business" />
              <Carousel.Caption>
                <div className="heroText wow fadeInDown">
                  <h3>Creating Digital Businesses.</h3>
                  <p>
                    Your product needs to differentiate your business.We make
                    that happen!
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img src={qualityresultImg} alt="Quality Results" />
              <Carousel.Caption>
                <div className="heroText wow fadeInDown">
                  <h3>Human-Powered High Quality Results.</h3>
                  <p>Our results are high quality.</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Hero;
