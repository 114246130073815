import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import WOW from 'wowjs';
import './Team.css';
// import maleImg from "../../components/Assets/Images/male.png";
// import femaleImg from "../../components/Assets/Images/female.png";

export class Team extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <div>
        <div className="team">
          {/* <div className="container text-center team-head wow fadeInDown">
            Our Team
          </div> */}
          <br />
          {/*<div className="container">
            <Row className="team-image">
              <Col sm>
                <div className="profilecard">
                  <img src={maleImg} alt="Founder" />
                  <p className="profile-name">Name Here</p>
                   <p className="profile-rank">Designation</p> 
                </div>
              </Col>
              <Col sm>
                <div className="profilecard">
                  <img src={femaleImg} alt="Founder" />
                  <p className="profile-name">Name Here</p>
                   <p className="profile-rank">Designation</p>
                </div>
              </Col>
              <Col sm>
                <div className="profilecard"></div>
              </Col>
            </Row>
            <br />
            <Row className="team-image">
              <Col sm>
                <div className="profilecard"></div>
              </Col>
              <Col sm>
                <div className="profilecard"></div>
              </Col>
              <Col sm>
                <div className="profilecard"></div>
              </Col>
            </Row>
          </div> */}
          <br />
          <br />
          <br />
        </div>
        <div className="start">
          <div className="startcontainer container">
            <h3>Take the next step!</h3>
            <p>
              Finding the right tech talent can be difficult and time consuming.
              At Nescotouch Technologies, we will make it easier and efficient
              for you.
            </p>

            <div>
              <Button href="" className="button1" variant="light">
                Start Hiring IT talents
              </Button>
              <Button as={Link} to="" className="button2" variant="secondary">
                Apply for IT training
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
