import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoIosRocket } from 'react-icons/io';
import { FcIdea } from 'react-icons/fc';
import { GiPodiumWinner } from 'react-icons/gi';
import { GiTeacher } from 'react-icons/gi';
import { GrCloudSoftware } from 'react-icons/gr';
import { GoVerified } from 'react-icons/go';
import WOW from 'wowjs';
import './Whoarewe.css';

import logoPng from '../../components/Assets/Images/logo.png';

export class Whoarewe extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div style={{ paddingTop: 150 }}>
        <div className="bg-background">
          <div className="whoarewe container text-center wow fadeInDown">
            <h3>Who are we?</h3>
            <p>
              Nescotouch Technologies is a UK Based IT Consultancy and software
              development company, founded in year 2024. We collaborates with
              clients to help them become highperformance businesses by
              combining unparalleled experience, comprehensive capabilities
              across all industries and business functions. We welcome the
              opportunity to work with you to address your technology
              development needs.
            </p>
          </div>
          <div
            className="mission container wow fadeInUp"
            style={{ marginTop: 50 }}
          >
            <Row>
              <Col sm>
                <h2>
                  <IoIosRocket />
                </h2>

                <h5>Our Mission</h5>
                <p>Helping our clients create their future.</p>
              </Col>

              <Col sm>
                <h2>
                  <FcIdea />
                </h2>

                <h5>Our Vision</h5>
                <p>
                  To become one of the world's leading companies, bringing
                  innovations to improve the way the world works and lives.
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <div className="service">
          <div className="container head text-center wow fadeInUp">
            <h3>What do we offer?</h3>
            <p>
              At Nescotouch Technologies, we are into the business of IT
              consulting and software development with also application
              development and maintenance services.
            </p>
          </div>
          {/* <div className="category container text-center">
            <img src={logoPng} alt="Nescotouch Technologies background" />
            <div className="roww">
              <Row className="wow fadeInUp">
                <Col sm>
                  <h2>
                    <GiTeacher />
                  </h2>

                  <h5>IT Staffing & Training</h5>
                  <p>
                    Helping our clients become their best self utilizing I.T
                  </p>
                </Col>

                <Col sm>
                  <h2>
                    <IoIosRocket />
                  </h2>
                  <h5>Infrastructure Services</h5>
                  <p>
                    Infrastructure Services can help you design and implement a
                    IT infrastructure that enables the digital business and
                    drives high performance.
                  </p>
                </Col>
              </Row>
              <Row className="wow fadeInUp">
                <Col sm>
                  <h2>
                    <GoVerified />
                  </h2>

                  <h5>Leadership & Governance</h5>
                  <p>
                    Leadership is then called upon to implement these principles
                    and guide your organization to be successful in its mission.
                  </p>
                </Col>

                <Col sm>
                  <h2>
                    <GrCloudSoftware />
                  </h2>
                  <h5>Application Development</h5>
                  <p>
                    Application development is the process of creating a
                    computer program or a set of programs to perform the
                    different tasks that a business requires.
                  </p>
                </Col>
              </Row>
            </div>
          </div> */}
        </div>
        <div className="guarantee container text-center wow fadeInUp">
          <h3>Our Guarantee</h3>
          <p>
            At Nescotouch Technologies, Our success is proof of our ability to
            continually deliver for our clients. We are committed to your
            complete satisfaction every step of the way.
          </p>
        </div>
      </div>
    );
  }
}

export default Whoarewe;
