import React from 'react';
import NavBar from '../components/NavBar/NavBar';
import Hero from '../Index/Hero/Hero';
import Whoarewe from './../Index/whoarewe/Whoarewe';
import Footer from '../components/Footer/Footer';

export default function Index() {
  return (
    <div>
      <NavBar />
      <Hero />
      <Whoarewe />
      <Footer />
    </div>
  );
}
